<template>
    <div class="mt-5 mx-3">
        <div class="content">
            <router-link :to="{ name: 'user-documentos' }" class="ml-2">
                <b-icon icon="arrow-left" size="is-small" />Atrás
            </router-link>
        </div>

        <div class="columns">
            <div class="column">
                <previsualizar-pdf />
            </div>
        </div>
    </div>
</template>

<script>
// FIXME: Cuando se va cambiando de documentos a visualizar -> Warning: getTextContent - ignoring errors during "GetTextContent: page 0" task: "Error: Worker task was terminated".
import PrevisualizarPdf from '@/components/Documentos/PrevisualizarPdf.vue';
import Message from '@/utils/Message';
import Utils from '@/utils/Utils';

export default {
    name: 'UserDocumentosVisualizador',
    metaInfo: {
        title: 'Mis Documentos'
    },
    components: {
        PrevisualizarPdf
    },
    computed: {
        selectedDocument() {
            return this.$store.state.documentos.selected;
        },
        isMobile() {
            return Utils.mobileCheck();
        }
    },
    async mounted() {
        if (!this.isMobile) {
            this.$router.push({
                name: 'user-documentos',
            });
            return;
        }

        await this.$store.dispatch('documentos/setLoading', true);
        try {
            await this.$store.dispatch('documentos/show', this.$route.params.id);
            await this.$store.dispatch('documentos/view', this.$route.params.id);
        } catch (err) {
            Message.showErrorMessage(this, `Error al obtener documento: ${err.message}`);
        } finally {
            await this.$store.dispatch('documentos/setLoading', false);
        }
    },
}
</script>

<style>

</style>
